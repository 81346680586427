<template>
    <div class="">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    style="cursor: pointer; max-width: max-content; white-space: nowrap"
                    v-on="on"
                    @click.stop="copy(copyString)"
                >
                    <v-icon
                        v-if="icon"
                        :color="iconColor"
                    >
                        mdi-checkbox-multiple-blank-outline
                    </v-icon>
                    <span v-else>
                        {{ copyString }}
                        <v-icon
                            :color="iconColor"
                        >
                            mdi-checkbox-multiple-blank-outline
                        </v-icon>
                    </span>
                </div>
            </template>
            <span>Copy to clickboard</span>
        </v-tooltip>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'

export default {
    props: {
        copyString: {
            type: String,
            required: true
        },
        icon: {
            type: Boolean
        },
        iconColor: {
            type: String,
            required: false,
            default: 'primary'
        },
        successMesage: {
            type: String,
            required: false,
            default: 'Copying to clipboard was successful!'
        }
    },
    methods: {
        copy(text) {
            navigator.clipboard.writeText(text).then(() => {
                eventBus.$emit('showSnackBar', this.successMesage, 'success')
            }, (err) => {
                eventBus.$emit('showSnackBar', err, 'error')
            });
        }
    }
}
</script>

<style>
    .x-small-icon{
        margin-top: -10px;
    }
</style>
